import { useState } from 'react';
import { Card, Nav, Row, Col, Tab } from 'react-bootstrap';
import { PageBreadcrumb } from '@/components';
import { Playground } from '@/pages/refactorChatbot/Playground';
import { WebPage } from '@/pages/refactorChatbot/tabs/WebPage';
import { QuestionAndAnswer } from '@/pages/refactorChatbot/tabs/QuestionAndAnswer';
import { AgentScript } from '@/pages/refactorChatbot/tabs/AgentScript';
import { Setting } from '@/pages/refactorChatbot/tabs/Setting';
import './style.css';

const tabContents = [
    {
        id: 'webPage',
        title: 'Web Page',
        content: <WebPage />,
    },
    {
        id: 'qna',
        title: 'Q&A',
        content: <QuestionAndAnswer />,
    },
    {
        id: 'agentScript',
        title: 'Agent Script',
        content: <AgentScript />,
    },
    {
        id: 'settings',
        title: 'Settings',
        content: <Setting />,
    },
];

export function ChatbotPlayground() {
    const [activeTab, setActiveTab] = useState('webPage');

    return (
        <>
            <PageBreadcrumb title="AI Assistant" subNames={[{ name: 'AI Assistant' }, { name: 'AI Assistant' }]} />
            <div className="row my-4">
                <div className="col-30">
                    <Playground />
                </div>
                <div className="col-70">
                    <Card>
                        <Card.Body>
                            <Tab.Container activeKey={activeTab} onSelect={(tabKey) => setActiveTab(tabKey ?? 'webPage')}>
                                <Nav variant="tabs" className="nav-bordered" as="ul">
                                    {tabContents.map((tab, index) => {
                                        return (
                                            <Nav.Item key={index.toString()} as="li">
                                                <Nav.Link eventKey={tab.id}>
                                                    <span className="d-none d-md-block">{tab.title}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </Nav>

                                <Tab.Content>
                                    {tabContents.map((tab, index) => {
                                        return (
                                            <Tab.Pane eventKey={tab.id} id={tab.id} key={index.toString()}>
                                                <Row>
                                                    <Col sm="12">
                                                        <div className="py-3">{tab.content}</div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        );
                                    })}
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}
