import { Button, Col, Form, Row } from 'react-bootstrap';
import { Form as HyperForm } from '@/components';
import useUpdateChatbotConfiguration from './component/useChatbotConfiguration.ts';
import { useTranslation } from 'react-i18next';
import { DefaultIdIndex, useAppContext } from '@/context/App.context';
import { OperationTimeslots } from './component/OperationTimeslots.tsx';

export const Setting = () => {
    const { t } = useTranslation();
    const { chatbotConfiguration, handleUpdateChatbotConfiguration, changeApplication, isFormValid } = useUpdateChatbotConfiguration();
    const { serviceIds } = useAppContext();

    return (
        <>
            <Row>
                <HyperForm onSubmit={handleUpdateChatbotConfiguration}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="text3" className="form-label">
                                    {t('ChatBotName')}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="applicationName"
                                    id="applicationName"
                                    value={chatbotConfiguration.applicationName}
                                    onChange={(e) => {
                                        changeApplication((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }));
                                    }}
                                />
                                <small>{t('ChatBot Name: The ChatBot will recognize its name as configured above.')}</small>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="text3" className="form-label">
                                    {t('CHATBOT_ID')}
                                </Form.Label>
                                <Form.Control type="text" name="applicationId" id="applicationId" disabled value={serviceIds.applicationIds[DefaultIdIndex]} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="text3" className="form-label">
                                {t('ChatBotDescription')}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                rows={5}
                                name="applicationDescription"
                                id="applicationDescription"
                                value={chatbotConfiguration.applicationDescription}
                                onChange={(e) => {
                                    changeApplication((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }));
                                }}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="text3" className="form-label">
                                {t('GREETING_MESSAGE')}
                            </Form.Label>
                            <Form.Control
                                name="greetingMessage"
                                id="greetingMessage"
                                value={chatbotConfiguration.greetingMessage}
                                onChange={(e) => {
                                    changeApplication((prev) => ({
                                        ...prev,
                                        greetingMessage: e.target.value,
                                    }));
                                }}
                                as={'textarea'}
                                rows={3}
                            />
                            <small>{t('This message will be shown on top of the ChatRoom')}</small>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="text3" className="form-label">
                                {t('ChatBot active message operation time')}
                            </Form.Label>
                            <OperationTimeslots
                                operationTimeslots={chatbotConfiguration.operationTimeSlots ?? []}
                                onChange={(value) => {
                                    changeApplication((prev) => ({
                                        ...prev,
                                        operationTimeSlots: value,
                                    }));
                                }}
                            />
                            <div className="mt-1">
                                <small>{t('EstablishATimeRange')}</small>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="justify-content-end">
                        <Button type="submit" className="w-auto" disabled={!isFormValid()}>
                            Update
                        </Button>
                    </Row>
                </HyperForm>
            </Row>
        </>
    );
};
