import { Loader } from '@/components';
import { ApplicationConfig } from '@/config/applicationConfig';
import { useAppContext } from '@/context/App.context';
import { useState } from 'react';
import './style.css';

export const Playground = () => {
    const { serviceIds } = useAppContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    return (
        <>
            {isLoading && <Loader />}
            <div style={{ minWidth: '400px', minHeight: '600px', height: '70vh', width: '45vh' }}>
                {!!serviceIds && (
                    <iframe
                        id="playground-iframe"
                        src={`${ApplicationConfig.chatbotURL}/?serviceId=${serviceIds.serviceId}&show=true&mode=playground`}
                        width="100%"
                        height="100%"
                        onLoad={() => {
                            setIsLoading(false);
                        }}
                    />
                )}
            </div>
        </>
    );
};
